export interface PIROZHOK {
    cz: string[];
    ru: string[];
    author: string;
    translator: string;
}

export const PIROZHKY: PIROZHOK[] = [
    {
        cz: ["Čím méně ženu my milujeme",
            "Tím horší je guláš a nechutná salám",
            "I děti se nepodobají vůbec",
            "Nám..."],
        ru: ["Чем меньше женщину мы любим",
            "Тем хуже борщ и жиже квас",
            "И дети как-то не похожи",
            "На нас..."],
        author: "unknown",
        translator: 'Sofijka'
    },
    {
        cz: ["Osudy, tváře čas mění",
            "Je nekonečný proud všedních věcí",
            "Je věčná jen poznámka mámy",
            "Nezapomeň čepici"],
        ru: ["Меняет время судьбы лица",
            "Нас гнёт поток житейских дел",
            "Но вечен лишь вопрос от мамы",
            "Ты ел"],
        author: "сафо",
        translator: 'Sofijka'
    },
    {
        cz: ["Až někdy trpce zalituješ",
            "Jen si vzpomeň na mě",
            "Pak bude trpčeji a litěji",
            "Dvojnásobně"],
        ru: ["Когда на сердце станет горько",
            "Ты просто вспомни обо мне",
            "И станет горше и обидней",
            "Вдвойне"],
        author: "Цай",
        translator: 'Sofijka'
    },
    {
        cz: ["Oleg jí řekl že je hudebník",
            "A doma nástrojů hromadu má",
            "Ale Oksaně v tváři bylo vidět",
            "Nedá"],
        ru: ["Олег сказал что он писатель",
            "Что начинающий фантаст",
            "Но по лицу Оксаны понял",
            "Не даст"],
        author: "seaL",
        translator: 'Sofijka'
    }, {
        cz: ["Po tréninku my uvidíme",
            "Jak vy přidáte na ceně",
            "A jak letí na kartu prachy",
            "Ke mně"],
        ru: ["Мы после тренинга увидим",
            "Как вы прибавите в цене",
            "И как текут на карту деньги",
            "Ко мне"],
        author: "AG",
        translator: 'Sofijka'
    }, {
        cz: ["Brzo ráno vyšla Olga ven",
            "Ve své kožešině",
            "Aby vypravila Olega",
            "K ženě"],
        ru: ["Оксана вышла на дорогу",
            "Под утро в старом шушуне",
            "И проводила николая",
            "К жене"],
        author: "МаГ",
        translator: 'Sofijka'
    },{
        cz: ["Je čím dál více černých dír",
            "Vesmír už není ten co kdy byl býval dítka",
            "Povídal Viktor vytahuje",
            "Krtka"],
        ru: ["Всё больше чёрных дыр ребята",
            "Вселенная уже не та",
            "Сказал аркадий извлекая",
            "Крота"],
        author: "Niloff",
        translator: 'Sofijka'
    },
]