import React from 'react';
import './App.scss';
import {HashRouter as Router, Route, Switch} from 'react-router-dom';
import {NavBar} from "./components/NavBar";
import {HomePage} from "./pages/HomePage";
import {NotFound} from "./pages/NotFound";


function App() {
    return (
        <Router>
            <NavBar/>
            <Switch>
                <Route exact path="/">
                    <HomePage/>
                </Route>
                <Route exact path="*">
                    <NotFound/>
                </Route>
            </Switch>
        </Router>
    );
}

export default App;
