import {Carousel, Container, Image} from "react-bootstrap";
import {PIROZHKY, PIROZHOK} from "../shared/PIROZHKY";
import bg from '../assets/bg.png';
import {PirozhokLyrics} from "../components/PirozhokLyrics";

export function HomePage() {
    return (
        <Container className="mt-5 pt-5 text-center">
            <Container className="bg-light">
                <Carousel prevLabel={null} nextLabel={null}>
                    {PIROZHKY.reverse().map((pirozhok: PIROZHOK, index) => {
                        return (<Carousel.Item interval={7000}>
                            <Image src={bg} height={window.innerWidth > 500 ? 450 : 550} width={1200}/>
                            <Carousel.Caption>
                                <Container className="lyrics">
                                    <h3>{index + 1} z {PIROZHKY.length}</h3>
                                    <PirozhokLyrics text={pirozhok.ru}/>
                                    <p><i>Autor: {pirozhok.author}</i></p>
                                    <br/>
                                    ***
                                    <br/>
                                    <br/>
                                    <PirozhokLyrics text={pirozhok.cz}/>
                                    <p><i>Překlad: {pirozhok.translator}</i></p>
                                </Container>
                            </Carousel.Caption>
                        </Carousel.Item>)
                    })}
                </Carousel>
            </Container>
        </Container>
    )
}