import {Nav, Navbar} from 'react-bootstrap';
import {LinkContainer} from "react-router-bootstrap";

export function NavBar() {
    return (
        <Navbar collapseOnSelect
                bg="light"
                variant="light"
                expand="lg"
                fixed="top"
                className="mb-3">
            <LinkContainer exact to="/">
                <Nav.Link className="logo-link-wrapper">
                    <Navbar.Brand className="logo-link">
                        <span className="logo-text">Pirožky</span>
                    </Navbar.Brand>
                </Nav.Link>
            </LinkContainer>
            <Navbar.Toggle aria-controls="basic-navbar-nav"/>
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto align-items-center">
                    <LinkContainer exact to="/">
                        <Nav.Link>
                            Domů
                        </Nav.Link>
                    </LinkContainer>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}