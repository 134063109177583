import {Container} from 'react-bootstrap';

export function PirozhokLyrics(props: { text: string[]; }) {
    return (
        <Container>
            {props.text.map((line: string) => {
                return <p>{line}</p>
            })}
        </Container>
    )
}